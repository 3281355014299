@font-face {
  font-family: 'Helvetica';
  src: url('../fonts/Helvetica.ttf');
}

body {
  font-family: 'Helvetica' !important;
}

a {
  text-decoration: none;
  color: #13141f;
}

a:hover {
  color: #FAA61A;
}

.bg-login .MuiInputBase-root {
  color: #fff;
}

.MuiTypography-root,
.MuiFormLabel-root {
  font-family: 'Helvetica' !important;
}

nav .MuiPaper-root,
.MuiDrawer-root .MuiPaper-root {
  background: #4c4c47;
  text-align: center;
  padding-top: 2rem;
}

nav a,
.MuiDrawer-root a {
  color: #fff !important;
}

nav .MuiListItemButton-root.active,
.MuiDrawer-root .MuiListItemButton-root.active {
  background-color: #FAA61A;
}

header {
  box-shadow: 0 0 2px 2px #4c4c4797 !important;
}

.bg-login {
  background-image: url('../img/bg-login.png');
  background-size: cover;
  background-position: center;
  background-color: #000000b9;
  background-blend-mode: overlay;
  text-align: -webkit-center;
  text-align: -moz-center;
  color: #fff;
  height: 100vh;
  padding: 12rem 0;
}

.box-form {
  background: #131313;
  padding: 2rem 3rem;
}

.btn-bg {
  background: #FAA61A;
  font-size: 18px;
  font-family: 'Helvetica';
  box-shadow: none !important;
}

.btn-bg:hover {
  background: #4c4c47;
}

.btn-add {
  background: #FAA61A;
}

.btn-add:hover {
  background-color: #4c4c47;
}

.box-cat img {
  height: 250px;
  object-fit: cover;
}

.list {
  top: 1em;
  right: 1em;
}

.box-img::after {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 250px;
  background-color: #13141f8b;
  border-radius: 0.25rem;
}

.upload__image-wrapper img {
  width: 100%;
  height: 150px;
  object-fit: cover;
}

.notifi-btn .MuiSvgIcon-root {
  color: #FAA61A;
  font-size: 40px;
}

.MuiBadge-badge {
  top: 5px;
  right: 5px;
  background-color: #4c4c47;
}

.about-grid h6 svg {
  background: #FAA61A;
  color: #fff;
  font-size: 25px;
  padding: 1px;
  margin-right: 10px;
}

.toast-close {
  background: #000;
  color: #fff;
  border: none;
  padding: 5px;
}

.order-num {
  position: absolute;
  left: 0;
  top: 0;
  background: #FAA61A;
  padding: 5px;
  display: inline-block;
  color: #fff;
  border-radius: 0.25rem !important;
}

.list-table {
  background: #FAA61A;
  font-weight: 700;
  padding: 5px 10px;
  font-size: 17px;
  color: #fff;
  border-radius: 6px;
  margin-bottom: 10px;
}

.list-table button {
  float: right;
}

.lang-inputs .MuiSvgIcon-root {
  position: absolute;
  right: 0;
  right: 10px;
    top: 30px;
    color: #FAA61Ad1;
}

.lang-inputs .MuiGrid-item {
  position: relative;
}
.lang-inputs .btn-add{
  width: 100%;
  font-size: 17px;
  padding: 10px;
}